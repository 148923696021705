<template>
  <div class="bg-wrap">
    <section class="mine-wrap">
      <div class="nav" v-if="!isOnePage">
        <div class="nav-wrap">
          <a-spin :spinning="menuLoading">
            <ul class="menu">
              <template v-for="(mod, index) in menus" :key="index">
                <div class="menu-title" @click="mod.isShow = !mod.isShow">
                  <div class="menu-title-text">
                    <img
                      v-if="mod.defaultIcon"
                      class="icon"
                      :src="
                        mod.menuIcon
                          ? mod.menuIcon
                          : require(`@/assets/image/mine/${mod.defaultIcon}.png`)
                      "
                      alt="icon"
                    />
                    <span class="icon" v-else></span>
                    <span>{{ mod.lang[languageActive] || mod.menuName }}</span>
                  </div>
                  <RightOutlined v-show="mod.isShow" />
                  <DownOutlined v-show="!mod.isShow" />
                </div>
                <div v-show="mod.isShow">
                  <template v-for="item in mod.children" :key="item.key">
                    <li
                      :class="{ active: currentView === item.key }"
                      @click="clickJump(item)"
                    >
                      <!-- <component :is="item.icon" /> -->
                      <span>{{
                        item.lang[languageActive] || item.menuName
                      }}</span>
                      <div class="bg"></div>
                    </li>
                  </template>
                </div>
              </template>
            </ul>
          </a-spin>
        </div>
      </div>
      <div class="content">
        <component :is="currentView" @emitJump="clickJump"></component>
      </div>
    </section>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
const files = require.context("./components", false, /\.vue$/);
const components = {};
files.keys().forEach((key) => {
  components[key.replace(/(\.\/|\.vue)/g, "")] = files(key).default;
});
import { ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { isLecturer, userInfo, getMenuConfig } from "@/api/user";
import { getReviewList } from "@/api/exam";
import OpenData from "@/components/OpenData.vue";
components.OpenData = OpenData;
export default {
  components,
  setup() {
    const { t: $t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const menus = ref([]);
    const menuLoading = ref(true);
    const menuCodeMap = {
      LearnCenter: { icon: "learning_center", component: "" },
      Interaction: { icon: "interaction_center", component: "" },
      Teaching: { icon: "educational", component: "" },
      Autonomous: { icon: "self_help", component: "" },
      MyLearn: { icon: "", component: "study" },
      ResourceCourse: { icon: "", component: "course" },
      TrainIndex: { icon: "", component: "face" },
      ExamManage: { icon: "", component: "exam" },
      ExamCyclic: { icon: "", component: "cyclic" },
      OperationActivity: { icon: "", component: "activity" },
      ResourceCert: { icon: "", component: "certificate" },
      LearningTrack: { icon: "", component: "history" },
      LiveIndex: { icon: "", component: "live" },
      ReportMember: { icon: "", component: "learningArchive" },
      Comment: { icon: "", component: "comments" },
      Favorite: { icon: "", component: "collect" },
      Like: { icon: "", component: "like" },
      ExamReview: { icon: "", component: "exam-review" },
      WorkMaking: { icon: "", component: "homework" },
      TrainPackage: { icon: "", component: "order" },
      ActiveCode: { icon: "", component: "codes" },
      HelperManage: { icon: "", component: "teaching" },
      LecturerApply: { icon: "", component: "teacher" },
      TrainDemandCollect: { icon: "", component: "demand" },
      Project: { icon: "", component: "project" },
    };
    const currentView = ref("");

    const user = computed(() => store.getters.userInfo);

    const isOnePage = ref(false);
    userInfo().then((res) => {
      if (res.ret === 0) {
        store.dispatch("setUserInfo", res.data);
      }
    });

    const languageActive = ref("zh_CN");
    watch(
      () => store.getters.lang,
      (newVal) => {
        languageActive.value = newVal.replace(/-/, "_");
      },
      { immediate: true }
    );
    watch(
      () => store.getters.companyInfo,
      (company) => {
        isOnePage.value = [1, 2, 3].includes(company.mobileModule);
      },
      { immediate: true }
    );

    const initMenu = async () => {
      const res = await getMenuConfig();
      const showLecturer = await showLecturerMenu();
      const showExam = await showExamReview();
      if (res.ret === 0 && res.data) {
        const list = res.data.filter((item) => {
          if (item.status === 1) {
            item.isShow = item.status === 1;
            if (!item.menuIcon && menuCodeMap[item.menuCode])
              item.defaultIcon = menuCodeMap[item.menuCode].icon || "";
            item.lang = {
              zh_CN: item.funcName,
              zh_TW: item.funcNameTW,
              en_US: item.funcNameEnglish,
            };
            item.children = item.children.filter((cl) => {
              if (cl.status === 1 && menuCodeMap[cl.menuCode]) {
                cl.isShow = cl.status === 1;
                cl.key = menuCodeMap[cl.menuCode].component || "";
                cl.lang = {
                  zh_CN: cl.funcName,
                  zh_TW: cl.funcNameTW,
                  en_US: cl.funcNameEnglish,
                };
                // 讲师申请
                if (cl.menuCode === "LecturerApply") {
                  cl.isShow = showLecturer;
                }
                // 考试批阅
                if (cl.menuCode === "ExamReview") {
                  cl.isShow = showExam;
                }
                return cl.isShow === true;
              }
            });
            item.children.sort((a, b) => a.sort - b.sort);
            item.isShow = item.children.length > 0;
            return item.isShow;
          }
        });
        menus.value = list;
        menuLoading.value = false;
        if (list.length) {
          if (route.hash) {
            let key = route.hash.slice(1);
            setCurrentView(key);
          } else {
            currentView.value = route.query.key || list[0].children[0].key;
          }
        }
      }
    };
    initMenu();

    // 是否是讲师
    const showLecturerMenu = async () => {
      const res = await isLecturer();
      return new Promise((resolve, reject) => {
        if (res.ret === 0) {
          // 1 是讲师&申请 2 是讲师&后台添加 3 非讲师&有记录 4 非讲师&没记录
          store.dispatch("setIsTeacher", res.data);
          resolve(res.data != 2);
        } else {
          reject(false);
        }
      }).catch(() => {});
    };
    // 考试批阅
    const showExamReview = async () => {
      const res = await getReviewList({ status: 0 });
      return new Promise((resolve, reject) => {
        if (res.data.list) {
          resolve(true);
        } else {
          reject(false);
        }
      }).catch(() => {});
    };

    const setCurrentView = (key) => {
      if (!key) return;
      let routeCurrent = key;
      if (route.query.taskType == 13 && !routeCurrent) routeCurrent = "demand";
      currentView.value = routeCurrent;
    };

    const clickJump = (item) => {
      if (route.query.keep) {
        // 菜单之间切换 清除下级页面的 缓存状态
        router.replace({ path: `/mine`, hash: '#' + item.key })
      } else {
        location.hash = item.key;
      }
    };

    watch(
      () => route.hash,
      (val) => {
        let key = val.slice(1);
        setCurrentView(key);
      },
      { deep: true }
    );

    return {
      menus,
      menuLoading,
      user,
      languageActive,
      currentView,
      clickJump,
      isOnePage,
    };
  },
};
</script>

<style lang="less" scoped>
.bg-wrap {
  background-color: @color-page-gray;
}
.mine-wrap {
  .mixinWrap();
  .mixinFlex(space-between);
  padding: 32px 0 40px;
}

.nav {
  width: 225px;
  .nav-wrap {
    .menu {
      padding: 8px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 10px 0 rgba(148, 148, 148, 0.1);
      min-height: 150px;
      &-title {
        padding: 20px 16px;
        .mixinFlex(space-between, center);
        cursor: pointer;
        &-text {
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 24px;
          .mixinFlex(flex-start, center);
          .icon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }
      }
      li {
        height: 46px;
        padding-left: 48px;
        line-height: 46px;
        font-size: 16px;
        position: relative;
        cursor: pointer;
        user-select: none;
        border-radius: 4px;
        margin-bottom: 8px;
        .bg {
          background-color: #fff;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0.1;
          border-radius: 8px;
        }
        &:hover {
          color: @color-theme;
        }
        &.active {
          color: @color-theme;
          .bg {
            background-color: @color-theme;
          }
        }
      }
    }
  }
}

.content {
  width: 955px;
}
</style>
